import React, { useState, useEffect } from 'react';

const JsonToHtml = ({ data }) => {
  if (typeof data !== 'object' || data === null) {
    return <span>{JSON.stringify(data)}</span>;
  }

  return (
    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
      {Object.entries(data).map(([key, value]) => (
        <li key={key}>
          <strong>{key}:</strong>{' '}
          {typeof value === 'object' && value !== null ? (
            <JsonToHtml data={value} />
          ) : (
            <span>{JSON.stringify(value)}</span>
          )}
        </li>
      ))}
    </ul>
  );
};

const App = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [steps, setSteps] = useState([]);

  const addStep = (step) => {
    setSteps(prevSteps => [...prevSteps, step]);
  };

  useEffect(() => {
    const fetchData = async () => {
      addStep("Starting to fetch data...");
      try {
        addStep("Sending request to https://dev.blade.razar.io/api/now/");
        const response = await fetch('https://dev.blade.razar.io/api/now/');
        addStep(`Received response with status: ${response.status}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        addStep("Response is OK. Parsing JSON...");
        const result = await response.json();
        addStep("JSON parsed successfully");
        setData(result);
        addStep("Data stored in state");
      } catch (e) {
        addStep(`Error occurred: ${e.message}`);
        setError(e.message);
      } finally {
        setLoading(false);
        addStep("Fetch operation completed");
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>RaZaR Blade API Test: Now</h1>
      <h2>Steps:</h2>
      <ol>
        {steps.map((step, index) => (
          <li key={index}>{step}</li>
        ))}
      </ol>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {data && (
        <div>
          <h2>Received Data (Raw JSON):</h2>
          <code>{JSON.stringify(data, null, 2)}</code>
          <h2>Received Data (HTML Representation):</h2>
          <code><JsonToHtml data={data} /></code>
        </div>
      )}
    </div>
  );
};

export default App;